import { DeltaParser } from '@avvoka/editor'
import { useCustomClauseStore } from '@stores/generic/customClause.store'
import type { SuperAstType } from '../../ast/ast'
import type { IAvvElement, MountedEditor } from '@avvoka/editor'
import { Editor } from '@avvoka/editor'
import AvvParser from '../../parser'
import { getCondForClauseAttribute, getLoopSettingsForClauseAttribute } from '../../QuestionnaireClauses'
import { removeUndefined, clone } from '@avvoka/shared'
import { UUID } from '@avvoka/shared'
import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'

export default class QEditorBridge {
  static getNode(editor: Editor) {
    return DeltaParser.parse(editor.getDelta())
  }

  static getQuestionnaireBody(questions: Backend.Questionnaire.IQuestion[]) {
    const avvFormat = new AvvParser.AVVFormat()

    const questionnaires = questions.reduce<Record<string, Backend.Questionnaire.IQuestion[]>>((obj, question) => {
      if (obj[question.party] == null) obj[question.party] = []
      obj[question.party].push(question)
      return obj
    }, {})

    for (const questionnaire of Object.keys(questionnaires)) {
      const questions = questionnaires[questionnaire]
      const builder = avvFormat.questionnaire(questionnaire)

      let lastCondition: string | undefined
      questions.forEach((q) => {
        const fn = builder[q.type as keyof typeof builder] as ((q: Backend.Questionnaire.IQuestion) => void) | undefined
        if (fn == null) {
          throw new Error(`Cannot save template: because type ${q.type} does not exist in questionnaire builder.`)
        }
        if (q.cond !== lastCondition) {
          if (lastCondition != null) {
            builder.endCondition()
            lastCondition = undefined
          }
          if (q.cond != null) {
            lastCondition = q.cond
            builder.condition(q.cond)
          }
        }
        fn.call(builder, q)
      })
      if (lastCondition != null) builder.endCondition()
      builder.endQuestionnaire()
    }

    return avvFormat.format
  }

  static getQuestionsFromAtts(
    atts: string[],
    questionTemplate: Backend.Questionnaire.IQuestion,
    editor: MountedEditor,
    calculateClauseCondition = false,
    pullDataFromAttLib = false
  ): Backend.Questionnaire.IQuestion[] {
    //TODO(oskar): Get all stuff from AvvStore from Pinia Store instead
    return atts.map((attr) => {
      let cond
      let loopSettingsFromCL
      if (calculateClauseCondition) {
        cond = getCondForClauseAttribute(attr, useCustomClauseStore(getActivePinia()).clauses, AvvStore.state.templatesClauseLibraryByAtt[attr] ?? [], editor)
        loopSettingsFromCL = getLoopSettingsForClauseAttribute(attr, useCustomClauseStore(getActivePinia()).clauses, AvvStore.state.templatesClauseLibraryByAtt[attr] ?? [])
      }
      let questionBoilerplate = {
        ...questionTemplate
      } as Backend.Questionnaire.IQuestion
      if (pullDataFromAttLib) {
        const userStore = useUserStore(getActivePinia())

        questionBoilerplate = {
          ...questionBoilerplate,
          ...removeUndefined(clone(userStore.attributeLibraryByAttribute[attr] ?? {}))
        }
        if (userStore.attributeLibraryByAttribute[attr]) {
          avv_dialog({
            snackStyle: 'notice',
            snackMessage: 'Question settings pulled from the attribute library.'
          })
        }
      }

      return {
        ...questionBoilerplate,
        desc: questionBoilerplate.desc.replace(`#{att}`, attr),
        cond,
        att: attr,
        opts: { ...questionBoilerplate.opts, ...loopSettingsFromCL, uuid: UUID.new() }
      } as Backend.Questionnaire.IQuestion
    })
  }

  node: IAvvElement

  constructor(editorOrNode: Editor | IAvvElement) {
    this.node = 'isElement' in editorOrNode ? editorOrNode : QEditorBridge.getNode(editorOrNode)
  }

  placeholderAttributes(): string[] {
    return this.node.extractChildren<IAvvElement>('AVV-PLACEHOLDER').map((p) => p.toText())
  }

  conditionTraverse(): { attributes: string[]; values: string[] } {
    const result = this.asts()
      .map((astString) => {
        return Ast.parse(astString) as SuperAstType
      })
      .filter((item) => item)
      .reduce<{ attributes: string[]; values: string[] }>(
        (acc, ast) => {
          const traverse = Ast.traverse(ast)
          return {
            attributes: acc.attributes.concat(traverse.attributes),
            values: acc.values.concat(traverse.values)
          }
        },
        { attributes: [], values: [] }
      )
    result.attributes = Ast.uniqueArray(result.attributes)
    result.values = Ast.uniqueArray(result.values)
    return result
  }

  conditionAttributes(): string[] {
    return this.conditionTraverse().attributes
  }

  asts(): string[] {
    return this.node
      .extractChildren<IAvvElement>(['AVV-CONDITION', 'AVV-IF', 'AVV-MATHOLDER'])
      .map((e) => e.attributes['data-condition'] || e.attributes['data-expression'])
      .filter((e) => e)
  }

  values(): string[] {
    return this.conditionTraverse().values
  }

  attributes(): string[] {
    return Ast.uniqueArray(this.conditionAttributes().concat(this.placeholderAttributes()))
  }
}
