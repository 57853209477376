import { Clause, Query, type MountedEditor } from '@avvoka/editor'
import type { SuperAstType } from '../features/ast/ast'
import Ast from '../features/ast/ast'
import { repeaterKeys } from './QuestionnaireLoops'
import { removeObjectKeys, removeUndefined } from '@avvoka/shared'

export const AVV_CLAUSE_SEPARATOR = '_aVVClauseSeparator_AVv'

export const getCondForClauseAttribute = (
  attr: string,
  clauses: Backend.Models.CustomClause.ForEditor[],
  clauseKeys: string[],
  editor: MountedEditor
) => {
  const isInClause = clauseKeys.length > 0
  let cond
  if (!isInClause) return cond
  const conditions = clauseKeys.map((clauseKey) => {
    const [id, variantKey] = clauseKey.split(AVV_CLAUSE_SEPARATOR)
    const allVariants = clauses.filter((c) => c.custom_clause_id == +id)
    const isInAllVariants =
      clauseKeys.filter((cKey) => cKey.includes(id)).length ==
      allVariants.length - 1
    if (isInAllVariants) return null
    const clause = allVariants.find((c) => c.key == variantKey)
    if (!clause) {
      console.error(`No clause found for key ${variantKey} in clause ${id}`)
      return
    }
    if (!clause.questions) {
      console.error(
        `No questions found for clause ${id} with variant ${variantKey}`
      )
      return
    }
    const question = clause.questions.find((q) => q.att === attr)
    if (!question) {
      console.error(
        `No question found for attribute ${attr} in clause ${id} with variant ${variantKey}`
      )
      return
    }
    const condFromClause = Ast.parse(question.cond, true)?.ast
    const clauseFromEditor = editor
      .query(
        Query<Clause>('clause').WithExactAttributes({
          'data-link-id': clause.custom_clause_id.toString()
        })
      )
      .find((blot) => blot.attributes['data-attribute'])
    if (!clauseFromEditor) {
      console.error(
        `No clause found in editor for clause ${id} with variant ${variantKey}`
      )
      return
    }
    const dependentAtt = clauseFromEditor?.attributes['data-attribute']
    const variantCond = getVariantCond(
      dependentAtt,
      variantKey,
      allVariants,
      clause
    )

    return condFromClause ? { And: [condFromClause, variantCond] } : variantCond
  })

  return concatConditions(conditions)
}

/*
 * This function generates condition based on the behavior of the clause in the editor.
 * Example:
 * If the att is in default clause, then the condition will be: DependentAtt != AnyOfTheNonDefaultVariantsKeys
 * If the att is in non-default clause, then the condition will be: DependentAtt = VariantKey
 */
const getVariantCond = (
  dependentAtt: string,
  variantKey: string,
  allVariants: Backend.Models.CustomClause.ForEditor[],
  clause: Backend.Models.CustomClause.ForEditor
) => {
  let variantCond
  if (clause.default) {
    const allNonDefaultVariantKeys = allVariants
      .filter((c) => !c.default && typeof c.variant_id == 'number')
      .map((c) => c.key)
    const allConds = allNonDefaultVariantKeys.map((varKey) => {
      return { NotEquals: [{ Att: dependentAtt }, { String: varKey }] }
    })
    variantCond = { And: allConds }
  } else {
    variantCond = { Equals: [{ Att: dependentAtt }, { String: variantKey }] }
  }
  return variantCond
}

/*
 * This function concats conditions into one condition, combining them with OR, and returns the stringified version of the condition.
 */
const concatConditions = (
  conditions: Array<SuperAstType | undefined | null>
) => {
  const filteredConditions = conditions.filter((c) => c)
  const concatedConditions =
    filteredConditions.length > 1
      ? { Or: filteredConditions }
      : filteredConditions[0]
  if (concatedConditions) {
    const wrappedConditions = { ast: concatedConditions } as SuperAstType
    return Ast.stringify(wrappedConditions)
  }
  return concatedConditions
}

export const getLoopSettingsForClauseAttribute = (
  attr: string,
  clauses: Backend.Models.CustomClause.ForEditor[],
  clauseKeys: string[]
) => {
  const isInClause = clauseKeys.length > 0
  if (!isInClause) return {}
  const questions = clauseKeys.map((clauseKey) => {
    const [id, variantKey] = clauseKey.split(AVV_CLAUSE_SEPARATOR)
    const clause = clauses.find(
      (c) => +id == c.custom_clause_id && c.key == variantKey
    )
    if (!clause) {
      console.error(`No clause found for key ${variantKey} in clause ${id}`)
      return
    }
    return clause.questions?.find((q) => q.att === attr)
  })

  return questions.reduce((acc, question) => {
    if (!question) return acc
    const loopSettings = removeUndefined(
      removeObjectKeys(
        question.opts,
        (key) =>
          !(
            repeaterKeys as unknown as keyof Backend.Questionnaire.IQuestion
          ).includes(key),
        true
      )
    )
    if (!loopSettings) return acc
    return { ...acc, ...loopSettings }
  }, {})
}
